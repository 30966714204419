
import {defineComponent} from "vue";
import {mapActions} from "vuex";

export default defineComponent({
  name: "AuthToken",

  data() {
    return {
      auth: null,
    }
  },

  mounted() {
    const payload = {
      code: this.$route.query.code ?? null,
      scope: this.$route.query.scope ?? null,
      state: this.$route.query.state ?? null,
    }

    this.postAuthToken(payload).then(data => {
      this.auth = data;
      window.close();
    });
  },

  methods: {
    ...mapActions({
      postAuthToken: 'google/auth/postAuthToken',
    }),
  }
});
